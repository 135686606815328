import React from 'react';
import './App.css';
import { Grid, Typography, Button } from '@mui/material';

function Home() {
    return (
        <Grid className="home-container" container spacing={4}>
            <Grid xs={6} id="home-text-container" item alignItems="center">
                <Grid>
                    <Grid>
                        <Typography align="center" variant="h2">
                            Koroonaviiruse testimine
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography align="center" id="home-text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum nec nisi quis molestie. Nullam bibendum eros sed lectus volutpat tempus. Nullam tristique velit nec tellus porta, eu sodales nisi sollicitudin.
                        </Typography>
                    </Grid>
                </Grid>
                <div align="center">
                    <Button variant="contained" size="large">Võta meiega ühendust!</Button>
                </div>
            </Grid>
            <Grid xs={6} className="home-image-container" item>
                <img alt='' className='home-page-image' src={require("./assets/images/home-swab.png")} />
            </Grid>
        </Grid >
    );
}

export default Home;