import axios from 'axios';

const customAxios = (token) => {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(function (config) {
        if (token)
            config.headers.Authorization = "Bearer " + token;
        return config;
    });

    axiosInstance.interceptors.response.use(function (response) {
        if (response.status === 401) {
            // verifyUser();
        }
        return response;
    });

    return axiosInstance;
};

export default customAxios;