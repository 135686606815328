import React, { useContext, useState } from "react";
import { UserContext } from "./context/UserContext";
import { Button, TextField, Paper, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [, setUserContext] = useContext(UserContext);

    let navigate = useNavigate();

    const handleChange = (event, fun) => {
        console.log(event.target.value);
        fun(event.target.value);
    };
    const formSubmit = e => {
        e.preventDefault();
        setError("");

        const genericErrorMessage = "Midagi läks valesti, palun proovi uuesti!";
        fetch(process.env.REACT_APP_API_ENDPOINT + "login/password", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password }),
        })
            .then(async response => {
                if (!response.ok) {
                    if (response.status === 400) {
                        setError("Mõlemad väljad on kohustuslikud!");
                    } else if (response.status === 401) {
                        setError("Sisestatud email või parool on vale.");
                    } else {
                        setError(genericErrorMessage);
                    }
                } else {
                    const data = await response.json();
                    setUserContext(oldValues => {
                        return { ...oldValues, token: data.token };
                    })
                    navigate('/testid');
                }
            })
            .catch(error => {
                setError(genericErrorMessage);
            });
    }
    return (
        <Grid className="home-container" container>
            <Grid xl={4} xs={6} id="home-text-container" item alignItems="center">
                <Paper
                    elevation={2}
                    className="login-background"
                    id="auth-form"
                >
                    {
                        error !== "" &&
                        error
                    }
                    <form>
                        <Grid container direction="column" spacing={2}>

                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Logi sisse
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="email"
                                    placeholder="Email"
                                    fullWidth
                                    name="username"
                                    variant="outlined"
                                    required
                                    autoFocus
                                    value={email}
                                    onChange={(e) => handleChange(e, setEmail)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="password"
                                    placeholder="Parool"
                                    fullWidth
                                    name="password"
                                    variant="outlined"
                                    required
                                    value={password}
                                    onChange={(e) => handleChange(e, setPassword)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="button-block"
                                    onClick={formSubmit}
                                >
                                    Logi sisse
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid xl={6} xs={6} className="home-image-container" item>
                <img alt='' className='home-page-image' src={require("./assets/images/home-swab.png")} />
            </Grid>
        </Grid >
    )
}

export default Login
