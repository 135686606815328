import { useContext } from 'react';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { UserContext } from "./context/UserContext";

export default function Footer() {
    const [userContext, ] = useContext(UserContext);

    return (
        <Paper elevation={4} id="footer">
            <Grid container direction="row" style={{"height": "100%"}} alignContent="center">
                <Grid item xs={1} />
                <Grid item xs={10} textAlign="center"><Typography variant="subtitle2">Copyright &copy; Farmalink 2022</Typography></Grid>
                {
                    !userContext.token &&
                    <Grid item xs={1}><Link underline='none' href="/login"><Typography>Logi sisse</Typography></Link></Grid>
                }
            </Grid>
        </Paper>
    );
}
