import { useCallback, useContext, useEffect } from "react";
import { UserContext } from "./context/UserContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Login from "./Login";
import Tests from "./Tests";
import Contact from "./Contact";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  const [, setUserContext] = useContext(UserContext)

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json();
        setUserContext(oldValues => {
          return { ...oldValues, token: data.token };
        })
      } else {
        setUserContext(oldValues => {
          return { ...oldValues, token: null };
        })
      }
      // call refreshToken every 5 minutes to renew the authentication token.
      setTimeout(verifyUser, 5 * 60 * 1000);
    })
  }, [setUserContext])

  useEffect(() => {
    verifyUser();
  }, [verifyUser])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Farmalink {(process.env.NODE_ENV === 'development') ? ' - TEST' : ''}</title>
        <link rel="canonical" href="https://farmalink.ee" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Page />} >
            <Route index element={<Home />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/testid" element={<Tests />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

function Page() {
  return (
    <div id="page-container">
      <Header />
      <div id="content-wrap">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

function NotFound() {
  return (
    404
  );
}


export default App;
