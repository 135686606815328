import { useContext, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { //BrowserRouter as Router,
    MenuItem, Button, Avatar, Container, Menu, Typography, IconButton, Toolbar, Box, AppBar
} from '@mui/material';
import { UserContext } from "./context/UserContext";
import { useNavigate, Link } from "react-router-dom";

const Header = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [userContext, setUserContext] = useContext(UserContext);
    var navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const logOut = () => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "logout", {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            },
        }).then(async response => {
            setUserContext(oldValues => {
                return { ...oldValues, details: undefined, token: null }
            })
            window.localStorage.setItem("logout", Date.now());
            navigate('/');
        });
    };

    return (
        <AppBar color="default" position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        Farmalink{(process.env.NODE_ENV === 'development') && ' - TEST'}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {
                                (userContext.token === undefined || userContext.token === null) &&
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"><Link className="menu-link" to="/">KODU</Link></Typography>
                                </MenuItem>
                            }
                            {
                                (userContext.token === undefined || userContext.token === null) &&
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"><Link className="menu-link" to="/kontakt">KONTAKT</Link></Typography>
                                </MenuItem>
                            }
                            {
                                userContext.token &&
                                <MenuItem onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"><Link className="menu-link" to="/testid">TESTID</Link></Typography>
                                </MenuItem>
                            }
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        Farmalink
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {
                            (userContext === undefined || userContext.token === undefined || userContext.token === null) &&
                            <>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'default', display: 'block' }}
                                    component={Link} to="/"
                                    className="menu-link"
                                >
                                    KODU
                                </Button>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'default', display: 'block' }}
                                    component={Link} to="/kontakt"
                                    className="menu-link"
                                >
                                    KONTAKT
                                </Button>
                            </>
                        }
                        {
                            userContext.token &&
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'default', display: 'block' }}
                            >
                                <Link className="menu-link" to="/testid">TESTID</Link>
                            </Button>
                        }
                    </Box>
                    {
                        userContext.token &&
                        <>
                            <Typography>Administraator&nbsp;</Typography>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar src="/static/images/avatar/2.jpg" />
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu && logOut}>
                                        <Typography textAlign="center">Logi välja</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </>
                    }
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default Header;
